// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v6.30.1
// source: go.chromium.org/luci/analysis/proto/v1/test_variant_branches.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Any } from "../../../../../google/protobuf/any.pb";
import { Timestamp } from "../../../../../google/protobuf/timestamp.pb";
import { Commit } from "../../../common/proto/git/commit.pb";
import { Variant } from "./common.pb";
import { Changelist, SourceRef } from "./sources.pb";
import { TestVerdict } from "./test_verdict.pb";

export const protobufPackage = "luci.analysis.v1";

/** A request message for `TestVariantBranches.Get` RPC. */
export interface GetRawTestVariantBranchRequest {
  /**
   * The name of the test variant branch.
   * It MUST be of the form projects/{PROJECT}/tests/{URL_ESCAPED_TEST_ID}/variants/{VARIANT_HASH}/refs/{REF_HASH}
   * where:
   * PROJECT is the LUCI Project of the test variant branch analysis.
   * URL_ESCAPED_TEST_ID is the test ID, escaped with
   * https://golang.org/pkg/net/url/#PathEscape. See also https://aip.dev/122.
   * VARIANT_HASH is the variant hash of the test variant analysis (16 lower-case-character hex string).
   * REF_HASH is the identity of the branch of the analysis. It is a 16 lower-case-character hex string.
   */
  readonly name: string;
}

/** Represents changepoint analysis raw data for a particular (project, test, variant, ref) in spanner. */
export interface TestVariantBranchRaw {
  /**
   * The name of the test variant branch.
   * Of the form projects/{PROJECT}/tests/{URL_ESCAPED_TEST_ID}/variants/{VARIANT_HASH}/refs/{REF_HASH}
   * where:
   * PROJECT is the LUCI Project of the test variant branch analysis.
   * URL_ESCAPED_TEST_ID is the test ID, escaped with
   * https://golang.org/pkg/net/url/#PathEscape. See also https://aip.dev/122.
   * VARIANT_HASH is the variant hash of the test variant analysis (16 lower-case-character hex string).
   * REF_HASH is the identity of the branch of the analysis. It is a 16 lower-case-character hex string.
   */
  readonly name: string;
  /** The LUCI Project. E.g. "chromium". */
  readonly project: string;
  /** The identity of the test. */
  readonly testId: string;
  /**
   * Hash of the variant, as 16 lowercase hexadecimal characters.
   * E.g. "96c68dc946ab4068".
   */
  readonly variantHash: string;
  /** Hash of the source branch, as 16 lowercase hexadecimal characters. */
  readonly refHash: string;
  /**
   * Describes one specific way of running the test, e.g. a specific bucket,
   * builder and a test suite.
   */
  readonly variant:
    | Variant
    | undefined;
  /** The branch in source control. */
  readonly ref:
    | SourceRef
    | undefined;
  /**
   * The finalized segments in the output buffer.
   *
   * Do not depend on this field. The internal protocol buffer stored in
   * Spanner is returned here for debug purposes only. We use
   * google.protobuf.Any to avoid revealing its type and having clients
   * possibly depend on it.
   *
   * If any tool needs to read this data, a wire proto (that is different
   * from the storage proto) needs to be defined and this field replaced
   * by a field of that wire type.
   */
  readonly finalizedSegments:
    | Any
    | undefined;
  /**
   * The finalizing segment in the output buffer.
   *
   * Do not depend on this field. The internal protocol buffer stored in
   * Spanner is returned here for debug purposes only. We use
   * google.protobuf.Any to avoid revealing its type and having clients
   * possibly depend on it.
   *
   * If any tool needs to read this data, a wire proto (that is different
   * from the storage proto) needs to be defined and this field replaced
   * by a field of that wire type.
   */
  readonly finalizingSegment:
    | Any
    | undefined;
  /**
   * Statistics about verdicts in the output buffer.
   *
   * Do not depend on this field. The internal protocol buffer stored in
   * Spanner is returned here for debug purposes only. We use
   * google.protobuf.Any to avoid revealing its type and having clients
   * possibly depend on it.
   *
   * If any tool needs to read this data, a wire proto (that is different
   * from the storage proto) needs to be defined and this field replaced
   * by a field of that wire type.
   */
  readonly statistics:
    | Any
    | undefined;
  /** The hot input buffer. */
  readonly hotBuffer:
    | InputBuffer
    | undefined;
  /** The cold input buffer. */
  readonly coldBuffer: InputBuffer | undefined;
}

/**
 * InputBuffer contains the verdict history of the test variant branch.
 * It is used for both the hot buffer and the cold buffer.
 */
export interface InputBuffer {
  /** The number of test runs in the input buffer. */
  readonly length: string;
  /**
   * Runs, sorted by commit position (oldest first), and
   * then result time (oldest first).
   */
  readonly runs: readonly InputBuffer_Run[];
}

/** Run represents a test run at a commit position. */
export interface InputBuffer_Run {
  /** The commit position of the run. */
  readonly commitPosition: string;
  /** The time that this run was produced, truncated to the nearest hour. */
  readonly hour: string | undefined;
  readonly counts: InputBuffer_Run_Counts | undefined;
}

export interface InputBuffer_Run_Counts {
  /** Number of expectedly passed results in the run. */
  readonly expectedPassCount: string;
  /** Number of expectedly failed results in the run. */
  readonly expectedFailCount: string;
  /** Number of expectedly crashed results in the run. */
  readonly expectedCrashCount: string;
  /** Number of expectedly aborted results in the run. */
  readonly expectedAbortCount: string;
  /** Number of unexpectedly passed results in the run. */
  readonly unexpectedPassCount: string;
  /** Number of unexpectedly failed results in the run. */
  readonly unexpectedFailCount: string;
  /** Number of unexpectedly crashed results in the run. */
  readonly unexpectedCrashCount: string;
  /** Number of unexpectedly aborted results in the run. */
  readonly unexpectedAbortCount: string;
}

/** A request message for `TestVariantBranches.BatchGet` RPC. */
export interface BatchGetTestVariantBranchRequest {
  /**
   * The name of the test variant branch.
   * It MUST be of the form projects/{PROJECT}/tests/{URL_ESCAPED_TEST_ID}/variants/{VARIANT_HASH}/refs/{REF_HASH}
   * where:
   * PROJECT is the LUCI Project of the test variant branch analysis.
   * URL_ESCAPED_TEST_ID is the test ID, escaped with
   * https://golang.org/pkg/net/url/#PathEscape. See also https://aip.dev/122.
   * VARIANT_HASH is the variant hash of the test variant analysis (16 lower-case-character hex string).
   * REF_HASH is the identity of the branch of the analysis. It is a 16 lower-case-character hex string.
   * Maximum of 100 can be retrieved, otherwise this RPC will return error.
   */
  readonly names: readonly string[];
}

export interface BatchGetTestVariantBranchResponse {
  /**
   * The return list will have the same length and order as request names list.
   * If a record is not found, the corresponding element will be set to nil.
   */
  readonly testVariantBranches: readonly TestVariantBranch[];
}

/** A request message for `TestVariantBranches.Query` RPC. */
export interface QueryTestVariantBranchRequest {
  /** Required. The LUCI project of the test variant branch. */
  readonly project: string;
  /** Required. The test id of test variant branches. */
  readonly testId: string;
  /** Required. The source ref of test variant branches. */
  readonly ref:
    | SourceRef
    | undefined;
  /**
   * The maximum number of entries to return.
   *
   * The service may return fewer than this value.
   * If unspecified, at most 100 variants will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to the next call MUST
   * match the call that provided the page token.
   */
  readonly pageToken: string;
}

export interface QueryTestVariantBranchResponse {
  /** The list of test variant branches ordered by `variant_hash` ASC. */
  readonly testVariantBranch: readonly TestVariantBranch[];
  /**
   * This field will be set if there are more results to return.
   * To get the next page of data, send the same request again, but include this
   * token.
   */
  readonly nextPageToken: string;
}

/** Represents changepoint analysis for a particular (project, test, variant, ref). */
export interface TestVariantBranch {
  /**
   * The name of the test variant branch.
   * Of the form projects/{PROJECT}/tests/{URL_ESCAPED_TEST_ID}/variants/{VARIANT_HASH}/refs/{REF_HASH}
   * where:
   * PROJECT is the LUCI Project of the test variant branch analysis.
   * URL_ESCAPED_TEST_ID is the test ID, escaped with
   * https://golang.org/pkg/net/url/#PathEscape. See also https://aip.dev/122.
   * VARIANT_HASH is the variant hash of the test variant analysis (16 lower-case-character hex string).
   * REF_HASH is the identity of the branch of the analysis. It is a 16 lower-case-character hex string.
   */
  readonly name: string;
  /** The LUCI Project. E.g. "chromium". */
  readonly project: string;
  /** The identity of the test. */
  readonly testId: string;
  /**
   * Hash of the variant, as 16 lowercase hexadecimal characters.
   * E.g. "96c68dc946ab4068".
   */
  readonly variantHash: string;
  /** Hash of the source branch, as 16 lowercase hexadecimal characters. */
  readonly refHash: string;
  /**
   * key:value pairs to specify the way of running a particular test.
   * e.g. a specific bucket, builder and a test suite.
   */
  readonly variant:
    | Variant
    | undefined;
  /** The branch in source control. */
  readonly ref:
    | SourceRef
    | undefined;
  /**
   * The test history represented as a set of [start commit position,
   * end commit position] segments, where segments have statistically
   * different failure and/or flake rates. The segments are ordered so that
   * the most recent segment appears first.
   * If a client is only interested in the current failure/flake rate, they
   * can just query the first segment.
   */
  readonly segments: readonly Segment[];
}

/**
 * Represents a period in history where the test had a consistent failure and
 * flake rate. Segments are separated by changepoints. Each segment captures
 * information about the changepoint which started it.
 * Same structure with bigquery proto here, but make a separate copy to allow it to evolve over time.
 * https://source.chromium.org/chromium/infra/infra/+/main:go/src/go.chromium.org/luci/analysis/proto/bq/test_variant_branch_row.proto;l=80
 *
 * Next ID: 10.
 */
export interface Segment {
  /**
   * If set, means the segment commenced with a changepoint.
   * If unset, means the segment began with the beginning of recorded
   * history for the segment. (All recorded history for a test variant branch
   * is deleted after 90 days of no results, so this means there were
   * no results for at least 90 days before the segment.)
   */
  readonly hasStartChangepoint: boolean;
  /**
   * The nominal commit position at which the segment starts (inclusive).
   * Guaranteed to be strictly greater than the end_position of the
   * chronologically previous segment (if any).
   * If this segment has a starting changepoint, this is the nominal position
   * of the changepoint (when the new test behaviour started).
   * If this segment does not have a starting changepoint, this is the
   * simply the first commit position in the known history of the test.
   */
  readonly startPosition: string;
  /**
   * The lower bound of the starting changepoint position in a 99% two-tailed
   * confidence interval. Inclusive.
   * Only set if has_start_changepoint is set.
   */
  readonly startPositionLowerBound99th: string;
  /**
   * The upper bound of the starting changepoint position in a 99% two-tailed
   * confidence interval. Inclusive.
   * Only set if has_start_changepoint is set.
   * When has_start_changepoint is set, the following invariant holds:
   * previous_segment.start_position <= start_position_lower_bound_99th <= start_position_upper_bound_99th <= end_position
   * where previous_segment refers to the chronologically previous segment.
   */
  readonly startPositionUpperBound99th: string;
  /**
   * The starting changepoint position distribution. Only available for new
   * changepoints detected from ~July 2024 onwards.
   */
  readonly startPositionDistribution:
    | Segment_PositionDistribution
    | undefined;
  /**
   * The earliest hour a test run at the indicated start_position
   * was recorded. Gives an approximate upper bound on the timestamp the
   * changepoint occurred, for systems which need to filter by date.
   */
  readonly startHour:
    | string
    | undefined;
  /**
   * The nominal commit position at which the segment ends (inclusive).
   * This is either the last recorded commit position in the test history
   * (for this test variant branch), or the position of the last run
   * seen before the next detected changepoint.
   */
  readonly endPosition: string;
  /**
   * The latest hour a test run at the indicated end_position
   * was recorded. Gives an approximate lower bound on the  timestamp
   * the changepoint occurred, for systems which need to filter by date.
   */
  readonly endHour:
    | string
    | undefined;
  /** Total number of test results/runs/verdicts in the segment. */
  readonly counts: Segment_Counts | undefined;
}

export interface Segment_PositionDistribution {
  /**
   * Changepoint position cumulative distribution function (CDF) probability
   * values at which the distribution is characterised. These are values
   * between 0.0 and 1.0.
   *
   * This distribution is guaranteed to have points sufficient to allow
   * exact extraction of 99.9%, 99%, 95%, 90%, 80%, 70%, 60%, 50% two-tailed
   * confidence intervals, and the distribution median (CDF = 0.5).
   *
   * Usage: to estimate the 99% confidence interval (two-tail), find the
   * index of the cdf equal to 0.005 (i.e. (1-CI)/2) and the index of the
   * cdf equal to or greater than 0.995 (i.e. 1-((1-CI)/2)).
   * Then lookup the corresponding source positions in source_positions.
   *
   * This array is sorted in ascending order.
   */
  readonly cdfs: readonly number[];
  /**
   * The source positions corresponding to each of the above cdf values.
   * I.E. source_positions[i] corresponds to cfs[i].
   *
   * The probability of a changepoint occuring at or before source_positions[i]
   * is at least cdfs[i].
   *
   * Invariant: length(source_positions) == len(cdfs).
   *
   * Because cdfs is in ascending order, this array is also in ascending
   * order.
   */
  readonly sourcePositions: readonly string[];
}

/**
 * Counts of source verdicts over a time period. Includes only
 * test results for submitted code changes. This is defined as:
 * (1) where the code under test was already submitted when the test ran
 *       (e.g. postsubmit builders)
 * (2) where the code under test was not submitted at the time the test ran,
 *     but was submitted immediately after (e.g. because the tests ran as part
 *     of a tryjob, the presubmit run the tryjob was triggered by succeeded,
 *     and submitted code as a result).
 *     Currently, when test results lead to CL submission via recycled CQ runs,
 *     they are not counted.
 * Source verdicts represent the aggregation of all test results at a given
 * source position.
 *
 * Statistics for test results and test runs can be added here when needed.
 */
export interface Segment_Counts {
  /** The number of unexpected non-skipped test results. */
  readonly unexpectedResults: number;
  /** The total number of non-skipped test results. */
  readonly totalResults: number;
  /** The number of expected passed test results. */
  readonly expectedPassedResults: number;
  /** The number of expected failed test results. */
  readonly expectedFailedResults: number;
  /** The number of expected crashed test results. */
  readonly expectedCrashedResults: number;
  /** The number of expected aborted test results. */
  readonly expectedAbortedResults: number;
  /** The number of unexpected passed test results. */
  readonly unexpectedPassedResults: number;
  /** The number of unexpected failed test results. */
  readonly unexpectedFailedResults: number;
  /** The number of unexpected crashed test results. */
  readonly unexpectedCrashedResults: number;
  /** The number of unexpected aborted test results. */
  readonly unexpectedAbortedResults: number;
  /**
   * The number of test runs which had an unexpected test result but were
   * not retried.
   */
  readonly unexpectedUnretriedRuns: number;
  /**
   * The number of test run which had an unexpected test result, were
   * retried, and still contained only unexpected test results.
   */
  readonly unexpectedAfterRetryRuns: number;
  /**
   * The number of test runs which had an unexpected test result, were
   * retried, and eventually recorded an expected test result.
   */
  readonly flakyRuns: number;
  /** The total number of test runs. */
  readonly totalRuns: number;
  /**
   * The number of source verdicts with only unexpected test results.
   * A source verdict refers to all test results at a commit position.
   */
  readonly unexpectedVerdicts: number;
  /**
   * The number of source verdicts with a mix of expected and unexpected test results.
   * A source verdict refers to all test results at a commit position.
   * As such, is a signal of either in- or cross- build flakiness.
   */
  readonly flakyVerdicts: number;
  /**
   * The total number of source verdicts.
   * A source verdict refers to all test results at a commit position.
   * As such, this is also the total number of source positions with
   * test results in the segment.
   */
  readonly totalVerdicts: number;
}

export interface QuerySourcePositionsRequest {
  /** The LUCI project. */
  readonly project: string;
  /** The identifier of a test. */
  readonly testId: string;
  /** The hash of the variant. */
  readonly variantHash: string;
  /** Hash of the source branch, as 16 lowercase hexadecimal characters. */
  readonly refHash: string;
  /**
   * The source position where to start listing from, in descending order (newest commit to older commits).
   * This start source position will be the largest source position in the response.
   */
  readonly startSourcePosition: string;
  /**
   * The maximum number of commits to return.
   *
   * The service may return fewer than this value.
   * If unspecified, at most 100 commits will be returned.
   * The maximum value is 1,000; values above 1,000 will be coerced to 1,000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `QuerySourcePositions` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `QuerySourcePositions` MUST
   * match the call that provided the page token.
   */
  readonly pageToken: string;
}

export interface QuerySourcePositionsResponse {
  /** Source positions in descending order, start from the commit at start_source_position. */
  readonly sourcePositions: readonly SourcePosition[];
  /** A page token for next QuerySourcePositionsRequest to fetch the next page of commits. */
  readonly nextPageToken: string;
}

export interface QuerySourceVerdictsRequest {
  /**
   * The name of the test variant branch to query.
   * It MUST be of the form projects/{PROJECT}/tests/{URL_ESCAPED_TEST_ID}/variants/{VARIANT_HASH}/refs/{REF_HASH}
   * where:
   * PROJECT is the LUCI Project of the test variant branch analysis.
   * URL_ESCAPED_TEST_ID is the test ID, escaped with
   * https://golang.org/pkg/net/url/#PathEscape. See also https://aip.dev/122.
   * VARIANT_HASH is the variant hash of the test variant analysis (16 lower-case-character hex string).
   * REF_HASH is the identity of the branch of the analysis. It is a 16 lower-case-character hex string.
   */
  readonly parent: string;
  /**
   * The source position to start querying from, inclusive. This is the maximum source
   * position to be returned in the response.
   *
   * Note: The start is the maximum source position (not the minimum) as this RPC
   * is designed for UIs that paginate backwards through source history.
   */
  readonly startSourcePosition: string;
  /**
   * The source position to stop querying at, exclusive. This is an exclusive lower
   * bound on the source positions returned in the response. As this is a lower bound,
   * it is required that end_source_position < start_source_position.
   *
   * In addition, no more than 1,000 source positions may be requested in one call,
   * i.e. start_source_position - end_source_position <= 1,000.
   */
  readonly endSourcePosition: string;
}

export interface QuerySourceVerdictsResponse {
  /**
   * Source verdicts in descending source position order. Only source verdicts
   * with test results are returned.
   */
  readonly sourceVerdicts: readonly QuerySourceVerdictsResponse_SourceVerdict[];
}

/**
 * VerdictStatus represents the status of a verdict as it is
 * seen by changepoint analysis. Changepoint analysis does not consider skipped
 * test results or exonerations.
 */
export enum QuerySourceVerdictsResponse_VerdictStatus {
  VERDICT_EXPECTATION_STATUS_UNSPECIFIED = 0,
  /** UNEXPECTED - The verdict (excluding skips) has only unexpected results. */
  UNEXPECTED = 1,
  /** EXPECTED - The verdict (excluding skips) has only expected results. */
  EXPECTED = 2,
  /** FLAKY - The verdict (excluding skips) has a mix of expected and unexpected results. */
  FLAKY = 3,
  /**
   * SKIPPED - The verdict has only skips, and as such was not used in change
   * point analysis.
   */
  SKIPPED = 4,
}

export function querySourceVerdictsResponse_VerdictStatusFromJSON(
  object: any,
): QuerySourceVerdictsResponse_VerdictStatus {
  switch (object) {
    case 0:
    case "VERDICT_EXPECTATION_STATUS_UNSPECIFIED":
      return QuerySourceVerdictsResponse_VerdictStatus.VERDICT_EXPECTATION_STATUS_UNSPECIFIED;
    case 1:
    case "UNEXPECTED":
      return QuerySourceVerdictsResponse_VerdictStatus.UNEXPECTED;
    case 2:
    case "EXPECTED":
      return QuerySourceVerdictsResponse_VerdictStatus.EXPECTED;
    case 3:
    case "FLAKY":
      return QuerySourceVerdictsResponse_VerdictStatus.FLAKY;
    case 4:
    case "SKIPPED":
      return QuerySourceVerdictsResponse_VerdictStatus.SKIPPED;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum QuerySourceVerdictsResponse_VerdictStatus",
      );
  }
}

export function querySourceVerdictsResponse_VerdictStatusToJSON(
  object: QuerySourceVerdictsResponse_VerdictStatus,
): string {
  switch (object) {
    case QuerySourceVerdictsResponse_VerdictStatus.VERDICT_EXPECTATION_STATUS_UNSPECIFIED:
      return "VERDICT_EXPECTATION_STATUS_UNSPECIFIED";
    case QuerySourceVerdictsResponse_VerdictStatus.UNEXPECTED:
      return "UNEXPECTED";
    case QuerySourceVerdictsResponse_VerdictStatus.EXPECTED:
      return "EXPECTED";
    case QuerySourceVerdictsResponse_VerdictStatus.FLAKY:
      return "FLAKY";
    case QuerySourceVerdictsResponse_VerdictStatus.SKIPPED:
      return "SKIPPED";
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum QuerySourceVerdictsResponse_VerdictStatus",
      );
  }
}

/** Test verdict is the aggregation of test results of a test variant in an invocation. */
export interface QuerySourceVerdictsResponse_TestVerdict {
  /** The ID of the top-level invocation that the test verdict belongs to. */
  readonly invocationId: string;
  /** The partition time of the test verdict. */
  readonly partitionTime:
    | string
    | undefined;
  /** The status of the test verdict as it is interpreted by changepoint analysis. */
  readonly status: QuerySourceVerdictsResponse_VerdictStatus;
  /**
   * The changelist(s) that were tested, if any. If there are more 10, only
   * the first 10 are returned here.
   */
  readonly changelists: readonly Changelist[];
}

/** Source verdict is the aggregation of test results of a test variant at a source position. */
export interface QuerySourceVerdictsResponse_SourceVerdict {
  /** The source position. */
  readonly position: string;
  /**
   * The overall status of the source verdict. Due to limitations in the implementation,
   * this currently reflects the aggregation of all test verdicts included in the
   * source verdict, and may include verdicts for unsubmitted code. As such, it may
   * differ from the actual source verdict status as used in change point analysis.
   */
  readonly status: QuerySourceVerdictsResponse_VerdictStatus;
  /**
   * The test verdicts at the source position. Note some of these test verdicts
   * may have not been used in change point analysis as they pertain to test results
   * for unsubmitted code.
   * Test verdicts will be ordered by ascending partition time, i.e. earliest test
   * verdict first.
   * Limited to at most 20 test verdicts.
   */
  readonly verdicts: readonly QuerySourceVerdictsResponse_TestVerdict[];
}

/** SourcePosition contains the commit and the test verdicts at a source position for a test variant branch. */
export interface SourcePosition {
  /** Source position. */
  readonly position: string;
  /** The git commit. */
  readonly commit:
    | Commit
    | undefined;
  /**
   * Test verdicts at this source position.
   * Test verdicts will be ordered by `partition_time` DESC.
   * At most 20 verdicts will be returned here.
   * Most of time, a test variant at the same source position has less than 20 verdicts.
   */
  readonly verdicts: readonly TestVerdict[];
}

export interface QueryChangepointAIAnalysisRequest {
  /** The LUCI project. */
  readonly project: string;
  /** The identifier of a test. */
  readonly testId: string;
  /** The hash of the variant. */
  readonly variantHash: string;
  /** Hash of the source branch, as 16 lowercase hexadecimal characters. */
  readonly refHash: string;
  /**
   * The nominal starting source position of the changepoint to query.
   * As the change point analysis is constantly changing and the current
   * nominal start position of the changepoint may have moved, the changepoint
   * nearest the queried position is returned.
   * If no changepoint is found near the given position, the error NOT_FOUND
   * is returned.
   */
  readonly startSourcePosition: string;
  /**
   * Settings to control the prompt used. To assist experimentation.
   * Optional.
   */
  readonly promptOptions: QueryChangepointAIAnalysisRequest_PromptOptions | undefined;
}

export interface QueryChangepointAIAnalysisRequest_PromptOptions {
  /**
   * The prompt prefix to use. If set, replaces the
   * default prompt prefix. Optional.
   */
  readonly prefix: string;
  /**
   * The prompt suffix to use. If set, replaces the
   * default prompt suffix. Optional.
   */
  readonly suffix: string;
}

export interface QueryChangepointAIAnalysisResponse {
  /**
   * The AI-generated analysis markdown. This content is produced by
   * generative AI and is experimental.
   */
  readonly analysisMarkdown: string;
  /** The prompt provided to generative AI. For debugging purposes only. */
  readonly prompt: string;
}

function createBaseGetRawTestVariantBranchRequest(): GetRawTestVariantBranchRequest {
  return { name: "" };
}

export const GetRawTestVariantBranchRequest: MessageFns<GetRawTestVariantBranchRequest> = {
  encode(message: GetRawTestVariantBranchRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetRawTestVariantBranchRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRawTestVariantBranchRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetRawTestVariantBranchRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetRawTestVariantBranchRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetRawTestVariantBranchRequest>): GetRawTestVariantBranchRequest {
    return GetRawTestVariantBranchRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetRawTestVariantBranchRequest>): GetRawTestVariantBranchRequest {
    const message = createBaseGetRawTestVariantBranchRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseTestVariantBranchRaw(): TestVariantBranchRaw {
  return {
    name: "",
    project: "",
    testId: "",
    variantHash: "",
    refHash: "",
    variant: undefined,
    ref: undefined,
    finalizedSegments: undefined,
    finalizingSegment: undefined,
    statistics: undefined,
    hotBuffer: undefined,
    coldBuffer: undefined,
  };
}

export const TestVariantBranchRaw: MessageFns<TestVariantBranchRaw> = {
  encode(message: TestVariantBranchRaw, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.project !== "") {
      writer.uint32(18).string(message.project);
    }
    if (message.testId !== "") {
      writer.uint32(26).string(message.testId);
    }
    if (message.variantHash !== "") {
      writer.uint32(34).string(message.variantHash);
    }
    if (message.refHash !== "") {
      writer.uint32(42).string(message.refHash);
    }
    if (message.variant !== undefined) {
      Variant.encode(message.variant, writer.uint32(50).fork()).join();
    }
    if (message.ref !== undefined) {
      SourceRef.encode(message.ref, writer.uint32(58).fork()).join();
    }
    if (message.finalizedSegments !== undefined) {
      Any.encode(message.finalizedSegments, writer.uint32(66).fork()).join();
    }
    if (message.finalizingSegment !== undefined) {
      Any.encode(message.finalizingSegment, writer.uint32(74).fork()).join();
    }
    if (message.statistics !== undefined) {
      Any.encode(message.statistics, writer.uint32(98).fork()).join();
    }
    if (message.hotBuffer !== undefined) {
      InputBuffer.encode(message.hotBuffer, writer.uint32(82).fork()).join();
    }
    if (message.coldBuffer !== undefined) {
      InputBuffer.encode(message.coldBuffer, writer.uint32(90).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TestVariantBranchRaw {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestVariantBranchRaw() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.project = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.testId = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.variantHash = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.refHash = reader.string();
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.variant = Variant.decode(reader, reader.uint32());
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.ref = SourceRef.decode(reader, reader.uint32());
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.finalizedSegments = Any.decode(reader, reader.uint32());
          continue;
        }
        case 9: {
          if (tag !== 74) {
            break;
          }

          message.finalizingSegment = Any.decode(reader, reader.uint32());
          continue;
        }
        case 12: {
          if (tag !== 98) {
            break;
          }

          message.statistics = Any.decode(reader, reader.uint32());
          continue;
        }
        case 10: {
          if (tag !== 82) {
            break;
          }

          message.hotBuffer = InputBuffer.decode(reader, reader.uint32());
          continue;
        }
        case 11: {
          if (tag !== 90) {
            break;
          }

          message.coldBuffer = InputBuffer.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TestVariantBranchRaw {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      project: isSet(object.project) ? globalThis.String(object.project) : "",
      testId: isSet(object.testId) ? globalThis.String(object.testId) : "",
      variantHash: isSet(object.variantHash) ? globalThis.String(object.variantHash) : "",
      refHash: isSet(object.refHash) ? globalThis.String(object.refHash) : "",
      variant: isSet(object.variant) ? Variant.fromJSON(object.variant) : undefined,
      ref: isSet(object.ref) ? SourceRef.fromJSON(object.ref) : undefined,
      finalizedSegments: isSet(object.finalizedSegments) ? Any.fromJSON(object.finalizedSegments) : undefined,
      finalizingSegment: isSet(object.finalizingSegment) ? Any.fromJSON(object.finalizingSegment) : undefined,
      statistics: isSet(object.statistics) ? Any.fromJSON(object.statistics) : undefined,
      hotBuffer: isSet(object.hotBuffer) ? InputBuffer.fromJSON(object.hotBuffer) : undefined,
      coldBuffer: isSet(object.coldBuffer) ? InputBuffer.fromJSON(object.coldBuffer) : undefined,
    };
  },

  toJSON(message: TestVariantBranchRaw): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.project !== "") {
      obj.project = message.project;
    }
    if (message.testId !== "") {
      obj.testId = message.testId;
    }
    if (message.variantHash !== "") {
      obj.variantHash = message.variantHash;
    }
    if (message.refHash !== "") {
      obj.refHash = message.refHash;
    }
    if (message.variant !== undefined) {
      obj.variant = Variant.toJSON(message.variant);
    }
    if (message.ref !== undefined) {
      obj.ref = SourceRef.toJSON(message.ref);
    }
    if (message.finalizedSegments !== undefined) {
      obj.finalizedSegments = Any.toJSON(message.finalizedSegments);
    }
    if (message.finalizingSegment !== undefined) {
      obj.finalizingSegment = Any.toJSON(message.finalizingSegment);
    }
    if (message.statistics !== undefined) {
      obj.statistics = Any.toJSON(message.statistics);
    }
    if (message.hotBuffer !== undefined) {
      obj.hotBuffer = InputBuffer.toJSON(message.hotBuffer);
    }
    if (message.coldBuffer !== undefined) {
      obj.coldBuffer = InputBuffer.toJSON(message.coldBuffer);
    }
    return obj;
  },

  create(base?: DeepPartial<TestVariantBranchRaw>): TestVariantBranchRaw {
    return TestVariantBranchRaw.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<TestVariantBranchRaw>): TestVariantBranchRaw {
    const message = createBaseTestVariantBranchRaw() as any;
    message.name = object.name ?? "";
    message.project = object.project ?? "";
    message.testId = object.testId ?? "";
    message.variantHash = object.variantHash ?? "";
    message.refHash = object.refHash ?? "";
    message.variant = (object.variant !== undefined && object.variant !== null)
      ? Variant.fromPartial(object.variant)
      : undefined;
    message.ref = (object.ref !== undefined && object.ref !== null) ? SourceRef.fromPartial(object.ref) : undefined;
    message.finalizedSegments = (object.finalizedSegments !== undefined && object.finalizedSegments !== null)
      ? Any.fromPartial(object.finalizedSegments)
      : undefined;
    message.finalizingSegment = (object.finalizingSegment !== undefined && object.finalizingSegment !== null)
      ? Any.fromPartial(object.finalizingSegment)
      : undefined;
    message.statistics = (object.statistics !== undefined && object.statistics !== null)
      ? Any.fromPartial(object.statistics)
      : undefined;
    message.hotBuffer = (object.hotBuffer !== undefined && object.hotBuffer !== null)
      ? InputBuffer.fromPartial(object.hotBuffer)
      : undefined;
    message.coldBuffer = (object.coldBuffer !== undefined && object.coldBuffer !== null)
      ? InputBuffer.fromPartial(object.coldBuffer)
      : undefined;
    return message;
  },
};

function createBaseInputBuffer(): InputBuffer {
  return { length: "0", runs: [] };
}

export const InputBuffer: MessageFns<InputBuffer> = {
  encode(message: InputBuffer, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.length !== "0") {
      writer.uint32(8).int64(message.length);
    }
    for (const v of message.runs) {
      InputBuffer_Run.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): InputBuffer {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInputBuffer() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.length = reader.int64().toString();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.runs.push(InputBuffer_Run.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InputBuffer {
    return {
      length: isSet(object.length) ? globalThis.String(object.length) : "0",
      runs: globalThis.Array.isArray(object?.runs) ? object.runs.map((e: any) => InputBuffer_Run.fromJSON(e)) : [],
    };
  },

  toJSON(message: InputBuffer): unknown {
    const obj: any = {};
    if (message.length !== "0") {
      obj.length = message.length;
    }
    if (message.runs?.length) {
      obj.runs = message.runs.map((e) => InputBuffer_Run.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<InputBuffer>): InputBuffer {
    return InputBuffer.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<InputBuffer>): InputBuffer {
    const message = createBaseInputBuffer() as any;
    message.length = object.length ?? "0";
    message.runs = object.runs?.map((e) => InputBuffer_Run.fromPartial(e)) || [];
    return message;
  },
};

function createBaseInputBuffer_Run(): InputBuffer_Run {
  return { commitPosition: "0", hour: undefined, counts: undefined };
}

export const InputBuffer_Run: MessageFns<InputBuffer_Run> = {
  encode(message: InputBuffer_Run, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.commitPosition !== "0") {
      writer.uint32(8).int64(message.commitPosition);
    }
    if (message.hour !== undefined) {
      Timestamp.encode(toTimestamp(message.hour), writer.uint32(18).fork()).join();
    }
    if (message.counts !== undefined) {
      InputBuffer_Run_Counts.encode(message.counts, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): InputBuffer_Run {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInputBuffer_Run() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.commitPosition = reader.int64().toString();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.hour = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.counts = InputBuffer_Run_Counts.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InputBuffer_Run {
    return {
      commitPosition: isSet(object.commitPosition) ? globalThis.String(object.commitPosition) : "0",
      hour: isSet(object.hour) ? globalThis.String(object.hour) : undefined,
      counts: isSet(object.counts) ? InputBuffer_Run_Counts.fromJSON(object.counts) : undefined,
    };
  },

  toJSON(message: InputBuffer_Run): unknown {
    const obj: any = {};
    if (message.commitPosition !== "0") {
      obj.commitPosition = message.commitPosition;
    }
    if (message.hour !== undefined) {
      obj.hour = message.hour;
    }
    if (message.counts !== undefined) {
      obj.counts = InputBuffer_Run_Counts.toJSON(message.counts);
    }
    return obj;
  },

  create(base?: DeepPartial<InputBuffer_Run>): InputBuffer_Run {
    return InputBuffer_Run.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<InputBuffer_Run>): InputBuffer_Run {
    const message = createBaseInputBuffer_Run() as any;
    message.commitPosition = object.commitPosition ?? "0";
    message.hour = object.hour ?? undefined;
    message.counts = (object.counts !== undefined && object.counts !== null)
      ? InputBuffer_Run_Counts.fromPartial(object.counts)
      : undefined;
    return message;
  },
};

function createBaseInputBuffer_Run_Counts(): InputBuffer_Run_Counts {
  return {
    expectedPassCount: "0",
    expectedFailCount: "0",
    expectedCrashCount: "0",
    expectedAbortCount: "0",
    unexpectedPassCount: "0",
    unexpectedFailCount: "0",
    unexpectedCrashCount: "0",
    unexpectedAbortCount: "0",
  };
}

export const InputBuffer_Run_Counts: MessageFns<InputBuffer_Run_Counts> = {
  encode(message: InputBuffer_Run_Counts, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.expectedPassCount !== "0") {
      writer.uint32(8).int64(message.expectedPassCount);
    }
    if (message.expectedFailCount !== "0") {
      writer.uint32(16).int64(message.expectedFailCount);
    }
    if (message.expectedCrashCount !== "0") {
      writer.uint32(24).int64(message.expectedCrashCount);
    }
    if (message.expectedAbortCount !== "0") {
      writer.uint32(32).int64(message.expectedAbortCount);
    }
    if (message.unexpectedPassCount !== "0") {
      writer.uint32(40).int64(message.unexpectedPassCount);
    }
    if (message.unexpectedFailCount !== "0") {
      writer.uint32(48).int64(message.unexpectedFailCount);
    }
    if (message.unexpectedCrashCount !== "0") {
      writer.uint32(56).int64(message.unexpectedCrashCount);
    }
    if (message.unexpectedAbortCount !== "0") {
      writer.uint32(64).int64(message.unexpectedAbortCount);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): InputBuffer_Run_Counts {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInputBuffer_Run_Counts() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.expectedPassCount = reader.int64().toString();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.expectedFailCount = reader.int64().toString();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.expectedCrashCount = reader.int64().toString();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.expectedAbortCount = reader.int64().toString();
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.unexpectedPassCount = reader.int64().toString();
          continue;
        }
        case 6: {
          if (tag !== 48) {
            break;
          }

          message.unexpectedFailCount = reader.int64().toString();
          continue;
        }
        case 7: {
          if (tag !== 56) {
            break;
          }

          message.unexpectedCrashCount = reader.int64().toString();
          continue;
        }
        case 8: {
          if (tag !== 64) {
            break;
          }

          message.unexpectedAbortCount = reader.int64().toString();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InputBuffer_Run_Counts {
    return {
      expectedPassCount: isSet(object.expectedPassCount) ? globalThis.String(object.expectedPassCount) : "0",
      expectedFailCount: isSet(object.expectedFailCount) ? globalThis.String(object.expectedFailCount) : "0",
      expectedCrashCount: isSet(object.expectedCrashCount) ? globalThis.String(object.expectedCrashCount) : "0",
      expectedAbortCount: isSet(object.expectedAbortCount) ? globalThis.String(object.expectedAbortCount) : "0",
      unexpectedPassCount: isSet(object.unexpectedPassCount) ? globalThis.String(object.unexpectedPassCount) : "0",
      unexpectedFailCount: isSet(object.unexpectedFailCount) ? globalThis.String(object.unexpectedFailCount) : "0",
      unexpectedCrashCount: isSet(object.unexpectedCrashCount) ? globalThis.String(object.unexpectedCrashCount) : "0",
      unexpectedAbortCount: isSet(object.unexpectedAbortCount) ? globalThis.String(object.unexpectedAbortCount) : "0",
    };
  },

  toJSON(message: InputBuffer_Run_Counts): unknown {
    const obj: any = {};
    if (message.expectedPassCount !== "0") {
      obj.expectedPassCount = message.expectedPassCount;
    }
    if (message.expectedFailCount !== "0") {
      obj.expectedFailCount = message.expectedFailCount;
    }
    if (message.expectedCrashCount !== "0") {
      obj.expectedCrashCount = message.expectedCrashCount;
    }
    if (message.expectedAbortCount !== "0") {
      obj.expectedAbortCount = message.expectedAbortCount;
    }
    if (message.unexpectedPassCount !== "0") {
      obj.unexpectedPassCount = message.unexpectedPassCount;
    }
    if (message.unexpectedFailCount !== "0") {
      obj.unexpectedFailCount = message.unexpectedFailCount;
    }
    if (message.unexpectedCrashCount !== "0") {
      obj.unexpectedCrashCount = message.unexpectedCrashCount;
    }
    if (message.unexpectedAbortCount !== "0") {
      obj.unexpectedAbortCount = message.unexpectedAbortCount;
    }
    return obj;
  },

  create(base?: DeepPartial<InputBuffer_Run_Counts>): InputBuffer_Run_Counts {
    return InputBuffer_Run_Counts.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<InputBuffer_Run_Counts>): InputBuffer_Run_Counts {
    const message = createBaseInputBuffer_Run_Counts() as any;
    message.expectedPassCount = object.expectedPassCount ?? "0";
    message.expectedFailCount = object.expectedFailCount ?? "0";
    message.expectedCrashCount = object.expectedCrashCount ?? "0";
    message.expectedAbortCount = object.expectedAbortCount ?? "0";
    message.unexpectedPassCount = object.unexpectedPassCount ?? "0";
    message.unexpectedFailCount = object.unexpectedFailCount ?? "0";
    message.unexpectedCrashCount = object.unexpectedCrashCount ?? "0";
    message.unexpectedAbortCount = object.unexpectedAbortCount ?? "0";
    return message;
  },
};

function createBaseBatchGetTestVariantBranchRequest(): BatchGetTestVariantBranchRequest {
  return { names: [] };
}

export const BatchGetTestVariantBranchRequest: MessageFns<BatchGetTestVariantBranchRequest> = {
  encode(message: BatchGetTestVariantBranchRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.names) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetTestVariantBranchRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetTestVariantBranchRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.names.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetTestVariantBranchRequest {
    return { names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [] };
  },

  toJSON(message: BatchGetTestVariantBranchRequest): unknown {
    const obj: any = {};
    if (message.names?.length) {
      obj.names = message.names;
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetTestVariantBranchRequest>): BatchGetTestVariantBranchRequest {
    return BatchGetTestVariantBranchRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetTestVariantBranchRequest>): BatchGetTestVariantBranchRequest {
    const message = createBaseBatchGetTestVariantBranchRequest() as any;
    message.names = object.names?.map((e) => e) || [];
    return message;
  },
};

function createBaseBatchGetTestVariantBranchResponse(): BatchGetTestVariantBranchResponse {
  return { testVariantBranches: [] };
}

export const BatchGetTestVariantBranchResponse: MessageFns<BatchGetTestVariantBranchResponse> = {
  encode(message: BatchGetTestVariantBranchResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.testVariantBranches) {
      TestVariantBranch.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetTestVariantBranchResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetTestVariantBranchResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.testVariantBranches.push(TestVariantBranch.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetTestVariantBranchResponse {
    return {
      testVariantBranches: globalThis.Array.isArray(object?.testVariantBranches)
        ? object.testVariantBranches.map((e: any) => TestVariantBranch.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BatchGetTestVariantBranchResponse): unknown {
    const obj: any = {};
    if (message.testVariantBranches?.length) {
      obj.testVariantBranches = message.testVariantBranches.map((e) => TestVariantBranch.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetTestVariantBranchResponse>): BatchGetTestVariantBranchResponse {
    return BatchGetTestVariantBranchResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetTestVariantBranchResponse>): BatchGetTestVariantBranchResponse {
    const message = createBaseBatchGetTestVariantBranchResponse() as any;
    message.testVariantBranches = object.testVariantBranches?.map((e) => TestVariantBranch.fromPartial(e)) || [];
    return message;
  },
};

function createBaseQueryTestVariantBranchRequest(): QueryTestVariantBranchRequest {
  return { project: "", testId: "", ref: undefined, pageSize: 0, pageToken: "" };
}

export const QueryTestVariantBranchRequest: MessageFns<QueryTestVariantBranchRequest> = {
  encode(message: QueryTestVariantBranchRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.project !== "") {
      writer.uint32(10).string(message.project);
    }
    if (message.testId !== "") {
      writer.uint32(18).string(message.testId);
    }
    if (message.ref !== undefined) {
      SourceRef.encode(message.ref, writer.uint32(26).fork()).join();
    }
    if (message.pageSize !== 0) {
      writer.uint32(32).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(42).string(message.pageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryTestVariantBranchRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryTestVariantBranchRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.project = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.testId = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.ref = SourceRef.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryTestVariantBranchRequest {
    return {
      project: isSet(object.project) ? globalThis.String(object.project) : "",
      testId: isSet(object.testId) ? globalThis.String(object.testId) : "",
      ref: isSet(object.ref) ? SourceRef.fromJSON(object.ref) : undefined,
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
    };
  },

  toJSON(message: QueryTestVariantBranchRequest): unknown {
    const obj: any = {};
    if (message.project !== "") {
      obj.project = message.project;
    }
    if (message.testId !== "") {
      obj.testId = message.testId;
    }
    if (message.ref !== undefined) {
      obj.ref = SourceRef.toJSON(message.ref);
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryTestVariantBranchRequest>): QueryTestVariantBranchRequest {
    return QueryTestVariantBranchRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryTestVariantBranchRequest>): QueryTestVariantBranchRequest {
    const message = createBaseQueryTestVariantBranchRequest() as any;
    message.project = object.project ?? "";
    message.testId = object.testId ?? "";
    message.ref = (object.ref !== undefined && object.ref !== null) ? SourceRef.fromPartial(object.ref) : undefined;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    return message;
  },
};

function createBaseQueryTestVariantBranchResponse(): QueryTestVariantBranchResponse {
  return { testVariantBranch: [], nextPageToken: "" };
}

export const QueryTestVariantBranchResponse: MessageFns<QueryTestVariantBranchResponse> = {
  encode(message: QueryTestVariantBranchResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.testVariantBranch) {
      TestVariantBranch.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryTestVariantBranchResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryTestVariantBranchResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.testVariantBranch.push(TestVariantBranch.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryTestVariantBranchResponse {
    return {
      testVariantBranch: globalThis.Array.isArray(object?.testVariantBranch)
        ? object.testVariantBranch.map((e: any) => TestVariantBranch.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: QueryTestVariantBranchResponse): unknown {
    const obj: any = {};
    if (message.testVariantBranch?.length) {
      obj.testVariantBranch = message.testVariantBranch.map((e) => TestVariantBranch.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryTestVariantBranchResponse>): QueryTestVariantBranchResponse {
    return QueryTestVariantBranchResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryTestVariantBranchResponse>): QueryTestVariantBranchResponse {
    const message = createBaseQueryTestVariantBranchResponse() as any;
    message.testVariantBranch = object.testVariantBranch?.map((e) => TestVariantBranch.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseTestVariantBranch(): TestVariantBranch {
  return {
    name: "",
    project: "",
    testId: "",
    variantHash: "",
    refHash: "",
    variant: undefined,
    ref: undefined,
    segments: [],
  };
}

export const TestVariantBranch: MessageFns<TestVariantBranch> = {
  encode(message: TestVariantBranch, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.project !== "") {
      writer.uint32(18).string(message.project);
    }
    if (message.testId !== "") {
      writer.uint32(26).string(message.testId);
    }
    if (message.variantHash !== "") {
      writer.uint32(34).string(message.variantHash);
    }
    if (message.refHash !== "") {
      writer.uint32(42).string(message.refHash);
    }
    if (message.variant !== undefined) {
      Variant.encode(message.variant, writer.uint32(50).fork()).join();
    }
    if (message.ref !== undefined) {
      SourceRef.encode(message.ref, writer.uint32(58).fork()).join();
    }
    for (const v of message.segments) {
      Segment.encode(v!, writer.uint32(66).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TestVariantBranch {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestVariantBranch() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.project = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.testId = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.variantHash = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.refHash = reader.string();
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.variant = Variant.decode(reader, reader.uint32());
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.ref = SourceRef.decode(reader, reader.uint32());
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.segments.push(Segment.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TestVariantBranch {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      project: isSet(object.project) ? globalThis.String(object.project) : "",
      testId: isSet(object.testId) ? globalThis.String(object.testId) : "",
      variantHash: isSet(object.variantHash) ? globalThis.String(object.variantHash) : "",
      refHash: isSet(object.refHash) ? globalThis.String(object.refHash) : "",
      variant: isSet(object.variant) ? Variant.fromJSON(object.variant) : undefined,
      ref: isSet(object.ref) ? SourceRef.fromJSON(object.ref) : undefined,
      segments: globalThis.Array.isArray(object?.segments) ? object.segments.map((e: any) => Segment.fromJSON(e)) : [],
    };
  },

  toJSON(message: TestVariantBranch): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.project !== "") {
      obj.project = message.project;
    }
    if (message.testId !== "") {
      obj.testId = message.testId;
    }
    if (message.variantHash !== "") {
      obj.variantHash = message.variantHash;
    }
    if (message.refHash !== "") {
      obj.refHash = message.refHash;
    }
    if (message.variant !== undefined) {
      obj.variant = Variant.toJSON(message.variant);
    }
    if (message.ref !== undefined) {
      obj.ref = SourceRef.toJSON(message.ref);
    }
    if (message.segments?.length) {
      obj.segments = message.segments.map((e) => Segment.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<TestVariantBranch>): TestVariantBranch {
    return TestVariantBranch.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<TestVariantBranch>): TestVariantBranch {
    const message = createBaseTestVariantBranch() as any;
    message.name = object.name ?? "";
    message.project = object.project ?? "";
    message.testId = object.testId ?? "";
    message.variantHash = object.variantHash ?? "";
    message.refHash = object.refHash ?? "";
    message.variant = (object.variant !== undefined && object.variant !== null)
      ? Variant.fromPartial(object.variant)
      : undefined;
    message.ref = (object.ref !== undefined && object.ref !== null) ? SourceRef.fromPartial(object.ref) : undefined;
    message.segments = object.segments?.map((e) => Segment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSegment(): Segment {
  return {
    hasStartChangepoint: false,
    startPosition: "0",
    startPositionLowerBound99th: "0",
    startPositionUpperBound99th: "0",
    startPositionDistribution: undefined,
    startHour: undefined,
    endPosition: "0",
    endHour: undefined,
    counts: undefined,
  };
}

export const Segment: MessageFns<Segment> = {
  encode(message: Segment, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.hasStartChangepoint !== false) {
      writer.uint32(8).bool(message.hasStartChangepoint);
    }
    if (message.startPosition !== "0") {
      writer.uint32(16).int64(message.startPosition);
    }
    if (message.startPositionLowerBound99th !== "0") {
      writer.uint32(24).int64(message.startPositionLowerBound99th);
    }
    if (message.startPositionUpperBound99th !== "0") {
      writer.uint32(32).int64(message.startPositionUpperBound99th);
    }
    if (message.startPositionDistribution !== undefined) {
      Segment_PositionDistribution.encode(message.startPositionDistribution, writer.uint32(74).fork()).join();
    }
    if (message.startHour !== undefined) {
      Timestamp.encode(toTimestamp(message.startHour), writer.uint32(42).fork()).join();
    }
    if (message.endPosition !== "0") {
      writer.uint32(48).int64(message.endPosition);
    }
    if (message.endHour !== undefined) {
      Timestamp.encode(toTimestamp(message.endHour), writer.uint32(58).fork()).join();
    }
    if (message.counts !== undefined) {
      Segment_Counts.encode(message.counts, writer.uint32(66).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Segment {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSegment() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.hasStartChangepoint = reader.bool();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.startPosition = reader.int64().toString();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.startPositionLowerBound99th = reader.int64().toString();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.startPositionUpperBound99th = reader.int64().toString();
          continue;
        }
        case 9: {
          if (tag !== 74) {
            break;
          }

          message.startPositionDistribution = Segment_PositionDistribution.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.startHour = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 6: {
          if (tag !== 48) {
            break;
          }

          message.endPosition = reader.int64().toString();
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.endHour = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.counts = Segment_Counts.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Segment {
    return {
      hasStartChangepoint: isSet(object.hasStartChangepoint) ? globalThis.Boolean(object.hasStartChangepoint) : false,
      startPosition: isSet(object.startPosition) ? globalThis.String(object.startPosition) : "0",
      startPositionLowerBound99th: isSet(object.startPositionLowerBound99th)
        ? globalThis.String(object.startPositionLowerBound99th)
        : "0",
      startPositionUpperBound99th: isSet(object.startPositionUpperBound99th)
        ? globalThis.String(object.startPositionUpperBound99th)
        : "0",
      startPositionDistribution: isSet(object.startPositionDistribution)
        ? Segment_PositionDistribution.fromJSON(object.startPositionDistribution)
        : undefined,
      startHour: isSet(object.startHour) ? globalThis.String(object.startHour) : undefined,
      endPosition: isSet(object.endPosition) ? globalThis.String(object.endPosition) : "0",
      endHour: isSet(object.endHour) ? globalThis.String(object.endHour) : undefined,
      counts: isSet(object.counts) ? Segment_Counts.fromJSON(object.counts) : undefined,
    };
  },

  toJSON(message: Segment): unknown {
    const obj: any = {};
    if (message.hasStartChangepoint !== false) {
      obj.hasStartChangepoint = message.hasStartChangepoint;
    }
    if (message.startPosition !== "0") {
      obj.startPosition = message.startPosition;
    }
    if (message.startPositionLowerBound99th !== "0") {
      obj.startPositionLowerBound99th = message.startPositionLowerBound99th;
    }
    if (message.startPositionUpperBound99th !== "0") {
      obj.startPositionUpperBound99th = message.startPositionUpperBound99th;
    }
    if (message.startPositionDistribution !== undefined) {
      obj.startPositionDistribution = Segment_PositionDistribution.toJSON(message.startPositionDistribution);
    }
    if (message.startHour !== undefined) {
      obj.startHour = message.startHour;
    }
    if (message.endPosition !== "0") {
      obj.endPosition = message.endPosition;
    }
    if (message.endHour !== undefined) {
      obj.endHour = message.endHour;
    }
    if (message.counts !== undefined) {
      obj.counts = Segment_Counts.toJSON(message.counts);
    }
    return obj;
  },

  create(base?: DeepPartial<Segment>): Segment {
    return Segment.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Segment>): Segment {
    const message = createBaseSegment() as any;
    message.hasStartChangepoint = object.hasStartChangepoint ?? false;
    message.startPosition = object.startPosition ?? "0";
    message.startPositionLowerBound99th = object.startPositionLowerBound99th ?? "0";
    message.startPositionUpperBound99th = object.startPositionUpperBound99th ?? "0";
    message.startPositionDistribution =
      (object.startPositionDistribution !== undefined && object.startPositionDistribution !== null)
        ? Segment_PositionDistribution.fromPartial(object.startPositionDistribution)
        : undefined;
    message.startHour = object.startHour ?? undefined;
    message.endPosition = object.endPosition ?? "0";
    message.endHour = object.endHour ?? undefined;
    message.counts = (object.counts !== undefined && object.counts !== null)
      ? Segment_Counts.fromPartial(object.counts)
      : undefined;
    return message;
  },
};

function createBaseSegment_PositionDistribution(): Segment_PositionDistribution {
  return { cdfs: [], sourcePositions: [] };
}

export const Segment_PositionDistribution: MessageFns<Segment_PositionDistribution> = {
  encode(message: Segment_PositionDistribution, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    writer.uint32(10).fork();
    for (const v of message.cdfs) {
      writer.double(v);
    }
    writer.join();
    writer.uint32(18).fork();
    for (const v of message.sourcePositions) {
      writer.int64(v);
    }
    writer.join();
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Segment_PositionDistribution {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSegment_PositionDistribution() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag === 9) {
            message.cdfs.push(reader.double());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.cdfs.push(reader.double());
            }

            continue;
          }

          break;
        }
        case 2: {
          if (tag === 16) {
            message.sourcePositions.push(reader.int64().toString());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.sourcePositions.push(reader.int64().toString());
            }

            continue;
          }

          break;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Segment_PositionDistribution {
    return {
      cdfs: globalThis.Array.isArray(object?.cdfs) ? object.cdfs.map((e: any) => globalThis.Number(e)) : [],
      sourcePositions: globalThis.Array.isArray(object?.sourcePositions)
        ? object.sourcePositions.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: Segment_PositionDistribution): unknown {
    const obj: any = {};
    if (message.cdfs?.length) {
      obj.cdfs = message.cdfs;
    }
    if (message.sourcePositions?.length) {
      obj.sourcePositions = message.sourcePositions;
    }
    return obj;
  },

  create(base?: DeepPartial<Segment_PositionDistribution>): Segment_PositionDistribution {
    return Segment_PositionDistribution.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Segment_PositionDistribution>): Segment_PositionDistribution {
    const message = createBaseSegment_PositionDistribution() as any;
    message.cdfs = object.cdfs?.map((e) => e) || [];
    message.sourcePositions = object.sourcePositions?.map((e) => e) || [];
    return message;
  },
};

function createBaseSegment_Counts(): Segment_Counts {
  return {
    unexpectedResults: 0,
    totalResults: 0,
    expectedPassedResults: 0,
    expectedFailedResults: 0,
    expectedCrashedResults: 0,
    expectedAbortedResults: 0,
    unexpectedPassedResults: 0,
    unexpectedFailedResults: 0,
    unexpectedCrashedResults: 0,
    unexpectedAbortedResults: 0,
    unexpectedUnretriedRuns: 0,
    unexpectedAfterRetryRuns: 0,
    flakyRuns: 0,
    totalRuns: 0,
    unexpectedVerdicts: 0,
    flakyVerdicts: 0,
    totalVerdicts: 0,
  };
}

export const Segment_Counts: MessageFns<Segment_Counts> = {
  encode(message: Segment_Counts, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.unexpectedResults !== 0) {
      writer.uint32(8).int32(message.unexpectedResults);
    }
    if (message.totalResults !== 0) {
      writer.uint32(16).int32(message.totalResults);
    }
    if (message.expectedPassedResults !== 0) {
      writer.uint32(24).int32(message.expectedPassedResults);
    }
    if (message.expectedFailedResults !== 0) {
      writer.uint32(32).int32(message.expectedFailedResults);
    }
    if (message.expectedCrashedResults !== 0) {
      writer.uint32(40).int32(message.expectedCrashedResults);
    }
    if (message.expectedAbortedResults !== 0) {
      writer.uint32(48).int32(message.expectedAbortedResults);
    }
    if (message.unexpectedPassedResults !== 0) {
      writer.uint32(56).int32(message.unexpectedPassedResults);
    }
    if (message.unexpectedFailedResults !== 0) {
      writer.uint32(64).int32(message.unexpectedFailedResults);
    }
    if (message.unexpectedCrashedResults !== 0) {
      writer.uint32(72).int32(message.unexpectedCrashedResults);
    }
    if (message.unexpectedAbortedResults !== 0) {
      writer.uint32(80).int32(message.unexpectedAbortedResults);
    }
    if (message.unexpectedUnretriedRuns !== 0) {
      writer.uint32(88).int32(message.unexpectedUnretriedRuns);
    }
    if (message.unexpectedAfterRetryRuns !== 0) {
      writer.uint32(96).int32(message.unexpectedAfterRetryRuns);
    }
    if (message.flakyRuns !== 0) {
      writer.uint32(104).int32(message.flakyRuns);
    }
    if (message.totalRuns !== 0) {
      writer.uint32(112).int32(message.totalRuns);
    }
    if (message.unexpectedVerdicts !== 0) {
      writer.uint32(120).int32(message.unexpectedVerdicts);
    }
    if (message.flakyVerdicts !== 0) {
      writer.uint32(128).int32(message.flakyVerdicts);
    }
    if (message.totalVerdicts !== 0) {
      writer.uint32(136).int32(message.totalVerdicts);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Segment_Counts {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSegment_Counts() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.unexpectedResults = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.totalResults = reader.int32();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.expectedPassedResults = reader.int32();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.expectedFailedResults = reader.int32();
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.expectedCrashedResults = reader.int32();
          continue;
        }
        case 6: {
          if (tag !== 48) {
            break;
          }

          message.expectedAbortedResults = reader.int32();
          continue;
        }
        case 7: {
          if (tag !== 56) {
            break;
          }

          message.unexpectedPassedResults = reader.int32();
          continue;
        }
        case 8: {
          if (tag !== 64) {
            break;
          }

          message.unexpectedFailedResults = reader.int32();
          continue;
        }
        case 9: {
          if (tag !== 72) {
            break;
          }

          message.unexpectedCrashedResults = reader.int32();
          continue;
        }
        case 10: {
          if (tag !== 80) {
            break;
          }

          message.unexpectedAbortedResults = reader.int32();
          continue;
        }
        case 11: {
          if (tag !== 88) {
            break;
          }

          message.unexpectedUnretriedRuns = reader.int32();
          continue;
        }
        case 12: {
          if (tag !== 96) {
            break;
          }

          message.unexpectedAfterRetryRuns = reader.int32();
          continue;
        }
        case 13: {
          if (tag !== 104) {
            break;
          }

          message.flakyRuns = reader.int32();
          continue;
        }
        case 14: {
          if (tag !== 112) {
            break;
          }

          message.totalRuns = reader.int32();
          continue;
        }
        case 15: {
          if (tag !== 120) {
            break;
          }

          message.unexpectedVerdicts = reader.int32();
          continue;
        }
        case 16: {
          if (tag !== 128) {
            break;
          }

          message.flakyVerdicts = reader.int32();
          continue;
        }
        case 17: {
          if (tag !== 136) {
            break;
          }

          message.totalVerdicts = reader.int32();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Segment_Counts {
    return {
      unexpectedResults: isSet(object.unexpectedResults) ? globalThis.Number(object.unexpectedResults) : 0,
      totalResults: isSet(object.totalResults) ? globalThis.Number(object.totalResults) : 0,
      expectedPassedResults: isSet(object.expectedPassedResults) ? globalThis.Number(object.expectedPassedResults) : 0,
      expectedFailedResults: isSet(object.expectedFailedResults) ? globalThis.Number(object.expectedFailedResults) : 0,
      expectedCrashedResults: isSet(object.expectedCrashedResults)
        ? globalThis.Number(object.expectedCrashedResults)
        : 0,
      expectedAbortedResults: isSet(object.expectedAbortedResults)
        ? globalThis.Number(object.expectedAbortedResults)
        : 0,
      unexpectedPassedResults: isSet(object.unexpectedPassedResults)
        ? globalThis.Number(object.unexpectedPassedResults)
        : 0,
      unexpectedFailedResults: isSet(object.unexpectedFailedResults)
        ? globalThis.Number(object.unexpectedFailedResults)
        : 0,
      unexpectedCrashedResults: isSet(object.unexpectedCrashedResults)
        ? globalThis.Number(object.unexpectedCrashedResults)
        : 0,
      unexpectedAbortedResults: isSet(object.unexpectedAbortedResults)
        ? globalThis.Number(object.unexpectedAbortedResults)
        : 0,
      unexpectedUnretriedRuns: isSet(object.unexpectedUnretriedRuns)
        ? globalThis.Number(object.unexpectedUnretriedRuns)
        : 0,
      unexpectedAfterRetryRuns: isSet(object.unexpectedAfterRetryRuns)
        ? globalThis.Number(object.unexpectedAfterRetryRuns)
        : 0,
      flakyRuns: isSet(object.flakyRuns) ? globalThis.Number(object.flakyRuns) : 0,
      totalRuns: isSet(object.totalRuns) ? globalThis.Number(object.totalRuns) : 0,
      unexpectedVerdicts: isSet(object.unexpectedVerdicts) ? globalThis.Number(object.unexpectedVerdicts) : 0,
      flakyVerdicts: isSet(object.flakyVerdicts) ? globalThis.Number(object.flakyVerdicts) : 0,
      totalVerdicts: isSet(object.totalVerdicts) ? globalThis.Number(object.totalVerdicts) : 0,
    };
  },

  toJSON(message: Segment_Counts): unknown {
    const obj: any = {};
    if (message.unexpectedResults !== 0) {
      obj.unexpectedResults = Math.round(message.unexpectedResults);
    }
    if (message.totalResults !== 0) {
      obj.totalResults = Math.round(message.totalResults);
    }
    if (message.expectedPassedResults !== 0) {
      obj.expectedPassedResults = Math.round(message.expectedPassedResults);
    }
    if (message.expectedFailedResults !== 0) {
      obj.expectedFailedResults = Math.round(message.expectedFailedResults);
    }
    if (message.expectedCrashedResults !== 0) {
      obj.expectedCrashedResults = Math.round(message.expectedCrashedResults);
    }
    if (message.expectedAbortedResults !== 0) {
      obj.expectedAbortedResults = Math.round(message.expectedAbortedResults);
    }
    if (message.unexpectedPassedResults !== 0) {
      obj.unexpectedPassedResults = Math.round(message.unexpectedPassedResults);
    }
    if (message.unexpectedFailedResults !== 0) {
      obj.unexpectedFailedResults = Math.round(message.unexpectedFailedResults);
    }
    if (message.unexpectedCrashedResults !== 0) {
      obj.unexpectedCrashedResults = Math.round(message.unexpectedCrashedResults);
    }
    if (message.unexpectedAbortedResults !== 0) {
      obj.unexpectedAbortedResults = Math.round(message.unexpectedAbortedResults);
    }
    if (message.unexpectedUnretriedRuns !== 0) {
      obj.unexpectedUnretriedRuns = Math.round(message.unexpectedUnretriedRuns);
    }
    if (message.unexpectedAfterRetryRuns !== 0) {
      obj.unexpectedAfterRetryRuns = Math.round(message.unexpectedAfterRetryRuns);
    }
    if (message.flakyRuns !== 0) {
      obj.flakyRuns = Math.round(message.flakyRuns);
    }
    if (message.totalRuns !== 0) {
      obj.totalRuns = Math.round(message.totalRuns);
    }
    if (message.unexpectedVerdicts !== 0) {
      obj.unexpectedVerdicts = Math.round(message.unexpectedVerdicts);
    }
    if (message.flakyVerdicts !== 0) {
      obj.flakyVerdicts = Math.round(message.flakyVerdicts);
    }
    if (message.totalVerdicts !== 0) {
      obj.totalVerdicts = Math.round(message.totalVerdicts);
    }
    return obj;
  },

  create(base?: DeepPartial<Segment_Counts>): Segment_Counts {
    return Segment_Counts.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Segment_Counts>): Segment_Counts {
    const message = createBaseSegment_Counts() as any;
    message.unexpectedResults = object.unexpectedResults ?? 0;
    message.totalResults = object.totalResults ?? 0;
    message.expectedPassedResults = object.expectedPassedResults ?? 0;
    message.expectedFailedResults = object.expectedFailedResults ?? 0;
    message.expectedCrashedResults = object.expectedCrashedResults ?? 0;
    message.expectedAbortedResults = object.expectedAbortedResults ?? 0;
    message.unexpectedPassedResults = object.unexpectedPassedResults ?? 0;
    message.unexpectedFailedResults = object.unexpectedFailedResults ?? 0;
    message.unexpectedCrashedResults = object.unexpectedCrashedResults ?? 0;
    message.unexpectedAbortedResults = object.unexpectedAbortedResults ?? 0;
    message.unexpectedUnretriedRuns = object.unexpectedUnretriedRuns ?? 0;
    message.unexpectedAfterRetryRuns = object.unexpectedAfterRetryRuns ?? 0;
    message.flakyRuns = object.flakyRuns ?? 0;
    message.totalRuns = object.totalRuns ?? 0;
    message.unexpectedVerdicts = object.unexpectedVerdicts ?? 0;
    message.flakyVerdicts = object.flakyVerdicts ?? 0;
    message.totalVerdicts = object.totalVerdicts ?? 0;
    return message;
  },
};

function createBaseQuerySourcePositionsRequest(): QuerySourcePositionsRequest {
  return {
    project: "",
    testId: "",
    variantHash: "",
    refHash: "",
    startSourcePosition: "0",
    pageSize: 0,
    pageToken: "",
  };
}

export const QuerySourcePositionsRequest: MessageFns<QuerySourcePositionsRequest> = {
  encode(message: QuerySourcePositionsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.project !== "") {
      writer.uint32(10).string(message.project);
    }
    if (message.testId !== "") {
      writer.uint32(18).string(message.testId);
    }
    if (message.variantHash !== "") {
      writer.uint32(26).string(message.variantHash);
    }
    if (message.refHash !== "") {
      writer.uint32(34).string(message.refHash);
    }
    if (message.startSourcePosition !== "0") {
      writer.uint32(40).int64(message.startSourcePosition);
    }
    if (message.pageSize !== 0) {
      writer.uint32(48).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(58).string(message.pageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QuerySourcePositionsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuerySourcePositionsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.project = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.testId = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.variantHash = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.refHash = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.startSourcePosition = reader.int64().toString();
          continue;
        }
        case 6: {
          if (tag !== 48) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuerySourcePositionsRequest {
    return {
      project: isSet(object.project) ? globalThis.String(object.project) : "",
      testId: isSet(object.testId) ? globalThis.String(object.testId) : "",
      variantHash: isSet(object.variantHash) ? globalThis.String(object.variantHash) : "",
      refHash: isSet(object.refHash) ? globalThis.String(object.refHash) : "",
      startSourcePosition: isSet(object.startSourcePosition) ? globalThis.String(object.startSourcePosition) : "0",
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
    };
  },

  toJSON(message: QuerySourcePositionsRequest): unknown {
    const obj: any = {};
    if (message.project !== "") {
      obj.project = message.project;
    }
    if (message.testId !== "") {
      obj.testId = message.testId;
    }
    if (message.variantHash !== "") {
      obj.variantHash = message.variantHash;
    }
    if (message.refHash !== "") {
      obj.refHash = message.refHash;
    }
    if (message.startSourcePosition !== "0") {
      obj.startSourcePosition = message.startSourcePosition;
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<QuerySourcePositionsRequest>): QuerySourcePositionsRequest {
    return QuerySourcePositionsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QuerySourcePositionsRequest>): QuerySourcePositionsRequest {
    const message = createBaseQuerySourcePositionsRequest() as any;
    message.project = object.project ?? "";
    message.testId = object.testId ?? "";
    message.variantHash = object.variantHash ?? "";
    message.refHash = object.refHash ?? "";
    message.startSourcePosition = object.startSourcePosition ?? "0";
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    return message;
  },
};

function createBaseQuerySourcePositionsResponse(): QuerySourcePositionsResponse {
  return { sourcePositions: [], nextPageToken: "" };
}

export const QuerySourcePositionsResponse: MessageFns<QuerySourcePositionsResponse> = {
  encode(message: QuerySourcePositionsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.sourcePositions) {
      SourcePosition.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QuerySourcePositionsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuerySourcePositionsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.sourcePositions.push(SourcePosition.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuerySourcePositionsResponse {
    return {
      sourcePositions: globalThis.Array.isArray(object?.sourcePositions)
        ? object.sourcePositions.map((e: any) => SourcePosition.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: QuerySourcePositionsResponse): unknown {
    const obj: any = {};
    if (message.sourcePositions?.length) {
      obj.sourcePositions = message.sourcePositions.map((e) => SourcePosition.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<QuerySourcePositionsResponse>): QuerySourcePositionsResponse {
    return QuerySourcePositionsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QuerySourcePositionsResponse>): QuerySourcePositionsResponse {
    const message = createBaseQuerySourcePositionsResponse() as any;
    message.sourcePositions = object.sourcePositions?.map((e) => SourcePosition.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseQuerySourceVerdictsRequest(): QuerySourceVerdictsRequest {
  return { parent: "", startSourcePosition: "0", endSourcePosition: "0" };
}

export const QuerySourceVerdictsRequest: MessageFns<QuerySourceVerdictsRequest> = {
  encode(message: QuerySourceVerdictsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    if (message.startSourcePosition !== "0") {
      writer.uint32(16).int64(message.startSourcePosition);
    }
    if (message.endSourcePosition !== "0") {
      writer.uint32(24).int64(message.endSourcePosition);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QuerySourceVerdictsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuerySourceVerdictsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.startSourcePosition = reader.int64().toString();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.endSourcePosition = reader.int64().toString();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuerySourceVerdictsRequest {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      startSourcePosition: isSet(object.startSourcePosition) ? globalThis.String(object.startSourcePosition) : "0",
      endSourcePosition: isSet(object.endSourcePosition) ? globalThis.String(object.endSourcePosition) : "0",
    };
  },

  toJSON(message: QuerySourceVerdictsRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.startSourcePosition !== "0") {
      obj.startSourcePosition = message.startSourcePosition;
    }
    if (message.endSourcePosition !== "0") {
      obj.endSourcePosition = message.endSourcePosition;
    }
    return obj;
  },

  create(base?: DeepPartial<QuerySourceVerdictsRequest>): QuerySourceVerdictsRequest {
    return QuerySourceVerdictsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QuerySourceVerdictsRequest>): QuerySourceVerdictsRequest {
    const message = createBaseQuerySourceVerdictsRequest() as any;
    message.parent = object.parent ?? "";
    message.startSourcePosition = object.startSourcePosition ?? "0";
    message.endSourcePosition = object.endSourcePosition ?? "0";
    return message;
  },
};

function createBaseQuerySourceVerdictsResponse(): QuerySourceVerdictsResponse {
  return { sourceVerdicts: [] };
}

export const QuerySourceVerdictsResponse: MessageFns<QuerySourceVerdictsResponse> = {
  encode(message: QuerySourceVerdictsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.sourceVerdicts) {
      QuerySourceVerdictsResponse_SourceVerdict.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QuerySourceVerdictsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuerySourceVerdictsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.sourceVerdicts.push(QuerySourceVerdictsResponse_SourceVerdict.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuerySourceVerdictsResponse {
    return {
      sourceVerdicts: globalThis.Array.isArray(object?.sourceVerdicts)
        ? object.sourceVerdicts.map((e: any) => QuerySourceVerdictsResponse_SourceVerdict.fromJSON(e))
        : [],
    };
  },

  toJSON(message: QuerySourceVerdictsResponse): unknown {
    const obj: any = {};
    if (message.sourceVerdicts?.length) {
      obj.sourceVerdicts = message.sourceVerdicts.map((e) => QuerySourceVerdictsResponse_SourceVerdict.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<QuerySourceVerdictsResponse>): QuerySourceVerdictsResponse {
    return QuerySourceVerdictsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QuerySourceVerdictsResponse>): QuerySourceVerdictsResponse {
    const message = createBaseQuerySourceVerdictsResponse() as any;
    message.sourceVerdicts =
      object.sourceVerdicts?.map((e) => QuerySourceVerdictsResponse_SourceVerdict.fromPartial(e)) || [];
    return message;
  },
};

function createBaseQuerySourceVerdictsResponse_TestVerdict(): QuerySourceVerdictsResponse_TestVerdict {
  return { invocationId: "", partitionTime: undefined, status: 0, changelists: [] };
}

export const QuerySourceVerdictsResponse_TestVerdict: MessageFns<QuerySourceVerdictsResponse_TestVerdict> = {
  encode(message: QuerySourceVerdictsResponse_TestVerdict, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.invocationId !== "") {
      writer.uint32(10).string(message.invocationId);
    }
    if (message.partitionTime !== undefined) {
      Timestamp.encode(toTimestamp(message.partitionTime), writer.uint32(18).fork()).join();
    }
    if (message.status !== 0) {
      writer.uint32(24).int32(message.status);
    }
    for (const v of message.changelists) {
      Changelist.encode(v!, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QuerySourceVerdictsResponse_TestVerdict {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuerySourceVerdictsResponse_TestVerdict() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.invocationId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.partitionTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.changelists.push(Changelist.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuerySourceVerdictsResponse_TestVerdict {
    return {
      invocationId: isSet(object.invocationId) ? globalThis.String(object.invocationId) : "",
      partitionTime: isSet(object.partitionTime) ? globalThis.String(object.partitionTime) : undefined,
      status: isSet(object.status) ? querySourceVerdictsResponse_VerdictStatusFromJSON(object.status) : 0,
      changelists: globalThis.Array.isArray(object?.changelists)
        ? object.changelists.map((e: any) => Changelist.fromJSON(e))
        : [],
    };
  },

  toJSON(message: QuerySourceVerdictsResponse_TestVerdict): unknown {
    const obj: any = {};
    if (message.invocationId !== "") {
      obj.invocationId = message.invocationId;
    }
    if (message.partitionTime !== undefined) {
      obj.partitionTime = message.partitionTime;
    }
    if (message.status !== 0) {
      obj.status = querySourceVerdictsResponse_VerdictStatusToJSON(message.status);
    }
    if (message.changelists?.length) {
      obj.changelists = message.changelists.map((e) => Changelist.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<QuerySourceVerdictsResponse_TestVerdict>): QuerySourceVerdictsResponse_TestVerdict {
    return QuerySourceVerdictsResponse_TestVerdict.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QuerySourceVerdictsResponse_TestVerdict>): QuerySourceVerdictsResponse_TestVerdict {
    const message = createBaseQuerySourceVerdictsResponse_TestVerdict() as any;
    message.invocationId = object.invocationId ?? "";
    message.partitionTime = object.partitionTime ?? undefined;
    message.status = object.status ?? 0;
    message.changelists = object.changelists?.map((e) => Changelist.fromPartial(e)) || [];
    return message;
  },
};

function createBaseQuerySourceVerdictsResponse_SourceVerdict(): QuerySourceVerdictsResponse_SourceVerdict {
  return { position: "0", status: 0, verdicts: [] };
}

export const QuerySourceVerdictsResponse_SourceVerdict: MessageFns<QuerySourceVerdictsResponse_SourceVerdict> = {
  encode(message: QuerySourceVerdictsResponse_SourceVerdict, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.position !== "0") {
      writer.uint32(8).int64(message.position);
    }
    if (message.status !== 0) {
      writer.uint32(16).int32(message.status);
    }
    for (const v of message.verdicts) {
      QuerySourceVerdictsResponse_TestVerdict.encode(v!, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QuerySourceVerdictsResponse_SourceVerdict {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuerySourceVerdictsResponse_SourceVerdict() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.position = reader.int64().toString();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.verdicts.push(QuerySourceVerdictsResponse_TestVerdict.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuerySourceVerdictsResponse_SourceVerdict {
    return {
      position: isSet(object.position) ? globalThis.String(object.position) : "0",
      status: isSet(object.status) ? querySourceVerdictsResponse_VerdictStatusFromJSON(object.status) : 0,
      verdicts: globalThis.Array.isArray(object?.verdicts)
        ? object.verdicts.map((e: any) => QuerySourceVerdictsResponse_TestVerdict.fromJSON(e))
        : [],
    };
  },

  toJSON(message: QuerySourceVerdictsResponse_SourceVerdict): unknown {
    const obj: any = {};
    if (message.position !== "0") {
      obj.position = message.position;
    }
    if (message.status !== 0) {
      obj.status = querySourceVerdictsResponse_VerdictStatusToJSON(message.status);
    }
    if (message.verdicts?.length) {
      obj.verdicts = message.verdicts.map((e) => QuerySourceVerdictsResponse_TestVerdict.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<QuerySourceVerdictsResponse_SourceVerdict>): QuerySourceVerdictsResponse_SourceVerdict {
    return QuerySourceVerdictsResponse_SourceVerdict.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<QuerySourceVerdictsResponse_SourceVerdict>,
  ): QuerySourceVerdictsResponse_SourceVerdict {
    const message = createBaseQuerySourceVerdictsResponse_SourceVerdict() as any;
    message.position = object.position ?? "0";
    message.status = object.status ?? 0;
    message.verdicts = object.verdicts?.map((e) => QuerySourceVerdictsResponse_TestVerdict.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSourcePosition(): SourcePosition {
  return { position: "0", commit: undefined, verdicts: [] };
}

export const SourcePosition: MessageFns<SourcePosition> = {
  encode(message: SourcePosition, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.position !== "0") {
      writer.uint32(8).int64(message.position);
    }
    if (message.commit !== undefined) {
      Commit.encode(message.commit, writer.uint32(18).fork()).join();
    }
    for (const v of message.verdicts) {
      TestVerdict.encode(v!, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SourcePosition {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSourcePosition() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.position = reader.int64().toString();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.commit = Commit.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.verdicts.push(TestVerdict.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SourcePosition {
    return {
      position: isSet(object.position) ? globalThis.String(object.position) : "0",
      commit: isSet(object.commit) ? Commit.fromJSON(object.commit) : undefined,
      verdicts: globalThis.Array.isArray(object?.verdicts)
        ? object.verdicts.map((e: any) => TestVerdict.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SourcePosition): unknown {
    const obj: any = {};
    if (message.position !== "0") {
      obj.position = message.position;
    }
    if (message.commit !== undefined) {
      obj.commit = Commit.toJSON(message.commit);
    }
    if (message.verdicts?.length) {
      obj.verdicts = message.verdicts.map((e) => TestVerdict.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<SourcePosition>): SourcePosition {
    return SourcePosition.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<SourcePosition>): SourcePosition {
    const message = createBaseSourcePosition() as any;
    message.position = object.position ?? "0";
    message.commit = (object.commit !== undefined && object.commit !== null)
      ? Commit.fromPartial(object.commit)
      : undefined;
    message.verdicts = object.verdicts?.map((e) => TestVerdict.fromPartial(e)) || [];
    return message;
  },
};

function createBaseQueryChangepointAIAnalysisRequest(): QueryChangepointAIAnalysisRequest {
  return { project: "", testId: "", variantHash: "", refHash: "", startSourcePosition: "0", promptOptions: undefined };
}

export const QueryChangepointAIAnalysisRequest: MessageFns<QueryChangepointAIAnalysisRequest> = {
  encode(message: QueryChangepointAIAnalysisRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.project !== "") {
      writer.uint32(10).string(message.project);
    }
    if (message.testId !== "") {
      writer.uint32(18).string(message.testId);
    }
    if (message.variantHash !== "") {
      writer.uint32(26).string(message.variantHash);
    }
    if (message.refHash !== "") {
      writer.uint32(34).string(message.refHash);
    }
    if (message.startSourcePosition !== "0") {
      writer.uint32(40).int64(message.startSourcePosition);
    }
    if (message.promptOptions !== undefined) {
      QueryChangepointAIAnalysisRequest_PromptOptions.encode(message.promptOptions, writer.uint32(50).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryChangepointAIAnalysisRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryChangepointAIAnalysisRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.project = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.testId = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.variantHash = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.refHash = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.startSourcePosition = reader.int64().toString();
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.promptOptions = QueryChangepointAIAnalysisRequest_PromptOptions.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryChangepointAIAnalysisRequest {
    return {
      project: isSet(object.project) ? globalThis.String(object.project) : "",
      testId: isSet(object.testId) ? globalThis.String(object.testId) : "",
      variantHash: isSet(object.variantHash) ? globalThis.String(object.variantHash) : "",
      refHash: isSet(object.refHash) ? globalThis.String(object.refHash) : "",
      startSourcePosition: isSet(object.startSourcePosition) ? globalThis.String(object.startSourcePosition) : "0",
      promptOptions: isSet(object.promptOptions)
        ? QueryChangepointAIAnalysisRequest_PromptOptions.fromJSON(object.promptOptions)
        : undefined,
    };
  },

  toJSON(message: QueryChangepointAIAnalysisRequest): unknown {
    const obj: any = {};
    if (message.project !== "") {
      obj.project = message.project;
    }
    if (message.testId !== "") {
      obj.testId = message.testId;
    }
    if (message.variantHash !== "") {
      obj.variantHash = message.variantHash;
    }
    if (message.refHash !== "") {
      obj.refHash = message.refHash;
    }
    if (message.startSourcePosition !== "0") {
      obj.startSourcePosition = message.startSourcePosition;
    }
    if (message.promptOptions !== undefined) {
      obj.promptOptions = QueryChangepointAIAnalysisRequest_PromptOptions.toJSON(message.promptOptions);
    }
    return obj;
  },

  create(base?: DeepPartial<QueryChangepointAIAnalysisRequest>): QueryChangepointAIAnalysisRequest {
    return QueryChangepointAIAnalysisRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryChangepointAIAnalysisRequest>): QueryChangepointAIAnalysisRequest {
    const message = createBaseQueryChangepointAIAnalysisRequest() as any;
    message.project = object.project ?? "";
    message.testId = object.testId ?? "";
    message.variantHash = object.variantHash ?? "";
    message.refHash = object.refHash ?? "";
    message.startSourcePosition = object.startSourcePosition ?? "0";
    message.promptOptions = (object.promptOptions !== undefined && object.promptOptions !== null)
      ? QueryChangepointAIAnalysisRequest_PromptOptions.fromPartial(object.promptOptions)
      : undefined;
    return message;
  },
};

function createBaseQueryChangepointAIAnalysisRequest_PromptOptions(): QueryChangepointAIAnalysisRequest_PromptOptions {
  return { prefix: "", suffix: "" };
}

export const QueryChangepointAIAnalysisRequest_PromptOptions: MessageFns<
  QueryChangepointAIAnalysisRequest_PromptOptions
> = {
  encode(
    message: QueryChangepointAIAnalysisRequest_PromptOptions,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.prefix !== "") {
      writer.uint32(10).string(message.prefix);
    }
    if (message.suffix !== "") {
      writer.uint32(18).string(message.suffix);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryChangepointAIAnalysisRequest_PromptOptions {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryChangepointAIAnalysisRequest_PromptOptions() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.prefix = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.suffix = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryChangepointAIAnalysisRequest_PromptOptions {
    return {
      prefix: isSet(object.prefix) ? globalThis.String(object.prefix) : "",
      suffix: isSet(object.suffix) ? globalThis.String(object.suffix) : "",
    };
  },

  toJSON(message: QueryChangepointAIAnalysisRequest_PromptOptions): unknown {
    const obj: any = {};
    if (message.prefix !== "") {
      obj.prefix = message.prefix;
    }
    if (message.suffix !== "") {
      obj.suffix = message.suffix;
    }
    return obj;
  },

  create(
    base?: DeepPartial<QueryChangepointAIAnalysisRequest_PromptOptions>,
  ): QueryChangepointAIAnalysisRequest_PromptOptions {
    return QueryChangepointAIAnalysisRequest_PromptOptions.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<QueryChangepointAIAnalysisRequest_PromptOptions>,
  ): QueryChangepointAIAnalysisRequest_PromptOptions {
    const message = createBaseQueryChangepointAIAnalysisRequest_PromptOptions() as any;
    message.prefix = object.prefix ?? "";
    message.suffix = object.suffix ?? "";
    return message;
  },
};

function createBaseQueryChangepointAIAnalysisResponse(): QueryChangepointAIAnalysisResponse {
  return { analysisMarkdown: "", prompt: "" };
}

export const QueryChangepointAIAnalysisResponse: MessageFns<QueryChangepointAIAnalysisResponse> = {
  encode(message: QueryChangepointAIAnalysisResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.analysisMarkdown !== "") {
      writer.uint32(10).string(message.analysisMarkdown);
    }
    if (message.prompt !== "") {
      writer.uint32(18).string(message.prompt);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryChangepointAIAnalysisResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryChangepointAIAnalysisResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.analysisMarkdown = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.prompt = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryChangepointAIAnalysisResponse {
    return {
      analysisMarkdown: isSet(object.analysisMarkdown) ? globalThis.String(object.analysisMarkdown) : "",
      prompt: isSet(object.prompt) ? globalThis.String(object.prompt) : "",
    };
  },

  toJSON(message: QueryChangepointAIAnalysisResponse): unknown {
    const obj: any = {};
    if (message.analysisMarkdown !== "") {
      obj.analysisMarkdown = message.analysisMarkdown;
    }
    if (message.prompt !== "") {
      obj.prompt = message.prompt;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryChangepointAIAnalysisResponse>): QueryChangepointAIAnalysisResponse {
    return QueryChangepointAIAnalysisResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryChangepointAIAnalysisResponse>): QueryChangepointAIAnalysisResponse {
    const message = createBaseQueryChangepointAIAnalysisResponse() as any;
    message.analysisMarkdown = object.analysisMarkdown ?? "";
    message.prompt = object.prompt ?? "";
    return message;
  },
};

/**
 * Provide methods to read data for test variant branches including
 * results from changepoint analysis, and test verdicts.
 *
 * Use of LUCI is subject to the Google [Terms of Service](https://policies.google.com/terms)
 * and [Privacy Policy](https://policies.google.com/privacy).
 */
export interface TestVariantBranches {
  /**
   * Retrieves the raw state of test variant branch analysis.
   * For reading test variant branch analyses from Spanner.
   * This enables us to inspect the state of a test variant branch
   * analysis in Spanner (which cannot easily inspected using SQL queries,
   * because the data is encoded).
   * This is currently only for LUCI Analysis admin users.
   */
  GetRaw(request: GetRawTestVariantBranchRequest): Promise<TestVariantBranchRaw>;
  /** Retrieves the current state of segments of test variant branch analysis in batches. */
  BatchGet(request: BatchGetTestVariantBranchRequest): Promise<BatchGetTestVariantBranchResponse>;
  /** Query queries test variant branches for a given test id and ref. */
  Query(request: QueryTestVariantBranchRequest): Promise<QueryTestVariantBranchResponse>;
  /** Lists commits and the test verdicts at these commits, starting from a source position. */
  QuerySourcePositions(request: QuerySourcePositionsRequest): Promise<QuerySourcePositionsResponse>;
  /** Lists source verdicts for a test variant branch. */
  QuerySourceVerdicts(request: QuerySourceVerdictsRequest): Promise<QuerySourceVerdictsResponse>;
  /**
   * Query for AI analysis of the possible culprits of a test changepoint.
   * Note: to use this RPC, you must be a member of the group `googlers`.
   */
  QueryChangepointAIAnalysis(request: QueryChangepointAIAnalysisRequest): Promise<QueryChangepointAIAnalysisResponse>;
}

export const TestVariantBranchesServiceName = "luci.analysis.v1.TestVariantBranches";
export class TestVariantBranchesClientImpl implements TestVariantBranches {
  static readonly DEFAULT_SERVICE = TestVariantBranchesServiceName;
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || TestVariantBranchesServiceName;
    this.rpc = rpc;
    this.GetRaw = this.GetRaw.bind(this);
    this.BatchGet = this.BatchGet.bind(this);
    this.Query = this.Query.bind(this);
    this.QuerySourcePositions = this.QuerySourcePositions.bind(this);
    this.QuerySourceVerdicts = this.QuerySourceVerdicts.bind(this);
    this.QueryChangepointAIAnalysis = this.QueryChangepointAIAnalysis.bind(this);
  }
  GetRaw(request: GetRawTestVariantBranchRequest): Promise<TestVariantBranchRaw> {
    const data = GetRawTestVariantBranchRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetRaw", data);
    return promise.then((data) => TestVariantBranchRaw.fromJSON(data));
  }

  BatchGet(request: BatchGetTestVariantBranchRequest): Promise<BatchGetTestVariantBranchResponse> {
    const data = BatchGetTestVariantBranchRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "BatchGet", data);
    return promise.then((data) => BatchGetTestVariantBranchResponse.fromJSON(data));
  }

  Query(request: QueryTestVariantBranchRequest): Promise<QueryTestVariantBranchResponse> {
    const data = QueryTestVariantBranchRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "Query", data);
    return promise.then((data) => QueryTestVariantBranchResponse.fromJSON(data));
  }

  QuerySourcePositions(request: QuerySourcePositionsRequest): Promise<QuerySourcePositionsResponse> {
    const data = QuerySourcePositionsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "QuerySourcePositions", data);
    return promise.then((data) => QuerySourcePositionsResponse.fromJSON(data));
  }

  QuerySourceVerdicts(request: QuerySourceVerdictsRequest): Promise<QuerySourceVerdictsResponse> {
    const data = QuerySourceVerdictsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "QuerySourceVerdicts", data);
    return promise.then((data) => QuerySourceVerdictsResponse.fromJSON(data));
  }

  QueryChangepointAIAnalysis(request: QueryChangepointAIAnalysisRequest): Promise<QueryChangepointAIAnalysisResponse> {
    const data = QueryChangepointAIAnalysisRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "QueryChangepointAIAnalysis", data);
    return promise.then((data) => QueryChangepointAIAnalysisResponse.fromJSON(data));
  }
}

interface Rpc {
  request(service: string, method: string, data: unknown): Promise<unknown>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(dateStr: string): Timestamp {
  const date = new globalThis.Date(dateStr);
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): string {
  let millis = (globalThis.Number(t.seconds) || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis).toISOString();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
